import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from "./storage";

// RTK Query
import { concernApi } from "./services/concernApi";
import { convoApi } from "./services/convoApi";
import { forumApi } from "./services/forumApi";
import { associateApi } from "./services/associateApi";
import { accountApi } from "./services/accountApi";
import { authApi } from "./services/authApi";
import { postApi } from "./services/postApi";
import { shopApi } from "./services/shopApi";
import { transactionApi } from "./services/transactionApi";
import { notificationApi } from "./services/notificationApi";

//Redux
import authReducer from "./persist/authentication/authSlice";
import otherPostReducer from "./persist/view/postSlice";
import userReducer from "./persist/account/userSlice";
import notifReducer from "./persist/account/notifSlice";
import shopReducer from "./persist/account/shopSlice";
import registerReducer from "./persist/authentication/registerSlice";
import { reportApi } from "./services/reportApi";

const rootReducer = combineReducers({
  auth: authReducer,
  userPosts: otherPostReducer,
  user: userReducer,
  shop: shopReducer,
  notif: notifReducer,
  register: registerReducer,
  [concernApi.reducerPath]: concernApi.reducer,
  [convoApi.reducerPath]: convoApi.reducer,
  [forumApi.reducerPath]: forumApi.reducer,
  [associateApi.reducerPath]: associateApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [shopApi.reducerPath]: shopApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  //stateReconciler: autoMergeLevel2,
  blacklist: [
    concernApi.reducerPath,
    convoApi.reducerPath,
    forumApi.reducerPath,
    reportApi.reducerPath,
    associateApi.reducerPath,
    accountApi.reducerPath,
    authApi.reducerPath,
    postApi.reducerPath,
    shopApi.reducerPath,
    transactionApi.reducerPath,
    notificationApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      concernApi.middleware,
      convoApi.middleware,
      forumApi.middleware,
      reportApi.middleware,
      postApi.middleware,
      associateApi.middleware,
      accountApi.middleware,
      authApi.middleware,
      shopApi.middleware,
      transactionApi.middleware,
      notificationApi.middleware
    ),
});

setupListeners(store.dispatch);
