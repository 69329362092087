import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const notificationApi = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        headers.set("Accept", `application/json`);
      }

      return headers;
    },
  }),
  tagTypes: ["Notifications", "Chats"],
  endpoints(build) {
    return {
      getNotification: build.query({
        query() {
          return {
            url: `user/notifications`,
            method: "GET",
          };
        },
        providesTags: ["Notifications"],
        transformResponse: (response) => response,
      }),

      getNotificationCount: build.query({
        query() {
          return {
            url: `user/notifications/count`,
            method: "GET",
          };
        },
        providesTags: ["Notifications"],
        transformResponse: (response) => response,
      }),

      getChatCount: build.query({
        query() {
          return {
            url: `/count/unread-chats`,
            method: "GET",
          };
        },
        providesTags: ["Chats"],
        transformResponse: (response) => response,
      }),

      readNotification: build.mutation({
        query(id) {
          return {
            url: `notifications/${id}/update`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["Notifications"],
        transformResponse: (response) => response,
      }),
    };
  },
});

export const {
  useGetNotificationQuery,
  useGetChatCountQuery,
  useGetNotificationCountQuery,
  useReadNotificationMutation,
} = notificationApi;
