import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const shopApi = createApi({
  reducerPath: "shopApi",
  baseQuery: fetchBaseQuery({
    //baseUrl: 'http://192.168.110.150:80/api',
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        headers.set("Accept", `application/json`);
      }

      return headers;
    },
  }),
  tagTypes: ["Shop", "Products", "ProductVariants", "FollowingProducts"],
  endpoints(build) {
    return {
      getUserShop: build.query({
        query(id) {
          return {
            url: `user/user-shop/${id}/details`,
            method: "GET",
          };
        },
        // providesTags: ['Shop'],
        transformResponse: (response) => response,
      }),
      hasShop: build.query({
        query(body) {
          return {
            url: "user/my-shop/",
            method: "GET",
            body,
          };
        },
        providesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      getMyShop: build.query({
        query(body) {
          return {
            url: "user/my-shop/details",
            method: "GET",
            body,
          };
        },
        providesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      getMyShopTransactionsCounts: build.query({
        query(body) {
          return {
            url: "user/my-shop/transactions",
            method: "GET",
            body,
          };
        },
        providesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      createShop: build.mutation({
        query(body) {
          return {
            url: "user/create/my-shop",
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      closeShop: build.mutation({
        query(id) {
          return {
            url: `user/close/my-shop/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      openShop: build.mutation({
        query(id) {
          return {
            url: `user/open/my-shop/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      addShopFeedback: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `shop/${id}/add-feedback`,
            method: "POST",
            body: data,
          };
        },
      }),

      updateShop: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/update/my-shop/${id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["Shop"],
        transformResponse: (response) => response,
      }),

      addProduct: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/my-shop/${id}/add-product`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Products"],
        transformResponse: (response) => response,
      }),

      getProducts: build.query({
        query(id) {
          return {
            url: `user/my-shop/${id}/products`,
            method: "GET",
          };
        },
        providesTags: ["Products"],
        transformResponse: (response) => response,
      }),

      updateProduct: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/my-shop/update/product/${id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["Products"],
        transformResponse: (response) => response,
      }),

      deleteProduct: build.mutation({
        query(id) {
          return {
            url: `user/my-shop/delete/product/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["Products"],
        transformResponse: (response) => response,
      }),

      addProductVariant: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/my-shop/product/${id}/add-variant`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["ProductVariants"],
        transformResponse: (response) => response,
      }),

      getProductVariants: build.query({
        query(id) {
          return {
            url: `user/my-shop/product/${id}/variant-list`,
            method: "GET",
          };
        },
        providesTags: ["ProductVariants"],
        transformResponse: (response) => response,
      }),

      addProductVariantSale: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/my-shop/product-variant/${id}/add-sale`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["ProductVariants"],
        transformResponse: (response) => response,
      }),

      addProductPhoto: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `user/my-shop/product/${id}/add-photo`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["ProductPhotos"],
        transformResponse: (response) => response,
      }),

      getProductPhotos: build.query({
        query(id) {
          return {
            url: `user/my-shop/product/${id}/photos`,
            method: "GET",
          };
        },
        providesTags: ["ProductPhotos"],
        transformResponse: (response) => response,
      }),

      deleteProductPhoto: build.mutation({
        query(id) {
          return {
            url: `user/my-shop/product/photo/${id}/delete`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ProductPhotos"],
        transformResponse: (response) => response,
      }),

      getProductRatings: build.query({
        query(id) {
          return {
            url: `product/${id}/rating`,
            method: "GET",
          };
        },
      }),

      getProductReviews: build.query({
        query(id) {
          return {
            url: `product/${id}/reviews`,
            method: "GET",
          };
        },
      }),
      getShopRatings: build.query({
        query(id) {
          return {
            url: `shop/${id}/rating`,
            method: "GET",
          };
        },
      }),

      getShopFeedbacks: build.query({
        query(id) {
          return {
            url: `shop/${id}/feedbacks`,
            method: "GET",
          };
        },
      }),

      updateProductVariant: build.mutation({
        query(body) {
          const { pid, id, data } = body;
          return {
            url: `user/my-shop/product/${pid}/variant/${id}/edit-variant`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["ProductVariants"],
        transformResponse: (response) => response,
      }),

      updateProductVariantQuantity: build.mutation({
        query(body) {
          const { pid, id, data } = body;
          return {
            url: `user/my-shop/product/${pid}/variant/${id}/add-variant-quantity`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["ProductVariants"],
        transformResponse: (response) => response,
      }),

      getFollowingProducts: build.query({
        query() {
          return {
            url: `user/following/products`,
            method: "GET",
          };
        },
        providesTags: ["FollowingProducts"],
        transformResponse: (response) => response,
      }),
      getCategoryProducts: build.query({
        query(category) {
          return {
            url: `market/products/categories/${category}`,
            method: "GET",
          };
        },
      }),

      getPublicProducts: build.query({
        query() {
          return {
            url: `market/public/products`,
            method: "GET",
          };
        },
      }),

      getSearchProduct: build.query({
        query(term) {
          return {
            url: `market/products/search/${term}`,
            method: "GET",
          };
        },
      }),

      getSearchShop: build.query({
        query(term) {
          return {
            url: `market/shops/search/${term}`,
            method: "GET",
          };
        },
      }),

      getShops: build.query({
        query() {
          return {
            url: `shops`,
            method: "GET",
          };
        },
      }),

      getTopShops: build.query({
        query() {
          return {
            url: `shops/top-shops`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useHasShopQuery,
  useCreateShopMutation,
  useUpdateShopMutation,
  useCloseShopMutation,
  useOpenShopMutation,
  useGetMyShopQuery,
  useGetMyShopTransactionsCountsQuery,
  useGetUserShopQuery,
  useGetShopsQuery,

  useAddProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,

  useAddProductVariantMutation,
  useGetProductVariantsQuery,
  useUpdateProductVariantMutation,
  useUpdateProductVariantQuantityMutation,
  useAddProductVariantSaleMutation,

  useAddProductPhotoMutation,
  useGetProductPhotosQuery,
  useDeleteProductPhotoMutation,

  useGetFollowingProductsQuery,
  useGetPublicProductsQuery,
  useGetCategoryProductsQuery,
  useGetSearchProductQuery,
  useGetSearchShopQuery,

  useAddShopFeedbackMutation,
  useGetProductRatingsQuery,
  useGetProductReviewsQuery,
  useGetShopRatingsQuery,
  useGetShopFeedbacksQuery,
  useGetTopShopsQuery,
} = shopApi;
