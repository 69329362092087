import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationCount: 0,
};

export const notifSlice = createSlice({
  name: "notif",
  initialState,

  reducers: {
    setNotifCount: (state, { payload: { count } }) => {
      state.notificationCount = count;
    },
    resetNotifCount: (state) => {
      state.notificationCount = 0;
    },
  },
});

export const { setNotifCount, resetNotifCount } = notifSlice.actions;

export default notifSlice.reducer;
