import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userPosts: [],
};

export const postSlice = createSlice({
  name: "userPosts",
  initialState,

  reducers: {
    setUserPosts: (state, { payload: { posts } }) => {
      state.userPosts = posts;
    },
    resetUserPosts: (state) => {
      state.userPosts = [];
    },
  },
});

export const { setUserPosts, resetUserPosts } = postSlice.actions;

export default postSlice.reducer;
