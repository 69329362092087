import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const concernApi = createApi({
  reducerPath: "concern",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        headers.set("Accept", `application/json`);
      }

      return headers;
    },
  }),
  endpoints(build) {
    return {
      addConcern: build.mutation({
        query(body) {
          return {
            url: "/concerns/add",
            method: "POST",
            body,
          };
        },
      }),
      addSystemFeedback: build.mutation({
        query(body) {
          return {
            url: "/system/add-feedback",
            method: "POST",
            body,
          };
        },
      }),

      getFAQs: build.query({
        query() {
          return {
            url: "/public/faqs",
            method: "GET",
          };
        },
      }),

      getAds: build.query({
        query(type) {
          return {
            url: `ads/all/${type}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useAddConcernMutation,
  useAddSystemFeedbackMutation,
  useGetFAQsQuery,
  useGetAdsQuery,
} = concernApi;
