import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const convoApi = createApi({
  reducerPath: "convo",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        headers.set("Accept", `application/json`);
      }

      return headers;
    },
  }),
  tagTypes: ["Chats", "Message", "Messages"],
  endpoints(build) {
    return {
      sendMessageFromFeed: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `/message/send-to/${id}`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Message", "Chats"],
      }),

      sendSharePost: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `/message/share-to/${id}`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Message", "Chats"],
      }),

      sendMessage: build.mutation({
        query(body) {
          const { id, data } = body;
          return {
            url: `/message/send-chat/convo/${id}`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Chats"],
      }),

      getConvos: build.query({
        query(term) {
          return {
            url: `convo/${term}/my-convos`,
            method: "GET",
          };
        },
        providesTags: ["Messages", "Message"],
      }),

      getChats: build.query({
        query(id) {
          return {
            url: `convo/${id}/all-chats`,
            method: "GET",
          };
        },
        providesTags: ["Chats"],
      }),

      readChats: build.mutation({
        query(id) {
          return {
            url: `convo/${id}/read-chats`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["Messages", "Message"],
      }),
    };
  },
});

export const {
  useSendMessageFromFeedMutation,
  useSendSharePostMutation,
  useSendMessageMutation,
  useReadChatsMutation,
  useGetConvosQuery,
  useGetChatsQuery,
} = convoApi;
